import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Dashboard from '../pages/Admin/Dashboard';
import CTAApplicants from '../pages/Admin/CTAApplicants';
import Login from '../pages/Login';
import StudentDashboard from '../pages/student/Dashboard';

import ITCApplicants from '../pages/Admin/ITCApplicant';
import CTAStudents from '../pages/Admin/CTAStudents';
import ITCStudents from '../pages/Admin/ITCStudents';
import UnreadEnquries from '../pages/Admin/UnreadEnquries';
import Messages from '../pages/Admin/Messages';
import JobApplicants from '../pages/Admin/JobApplicants';
import JobVacancies from '../pages/Admin/JobVacancies';
import Users from '../pages/Admin/Users';
import Profile from '../components/student/Profile';
import Registration from '../components/student/Registration';
import Signup from '../components/student/Signup';
import { AdminPrivateRoutes, RegPrivateRoutes, StudentPrivateRoutes } from '../utils/PrivateRoutes';
import Faqs from '../components/student/Faqs';
import BankingDetails from '../components/student/BankingDetails';
const MainRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/itc_students" element={<ITCStudents />} />
          <Route path="/cta_students" element={<CTAStudents />} />
          <Route path="/cta_applicants" element={<CTAApplicants/>} />
          <Route path="/itc_applicants" element={<ITCApplicants />} />
          <Route path="/unread_enquiries" element={<UnreadEnquries />} />
          <Route path="/job_applicants" element={<JobApplicants />} />
          <Route path="/job_vacancies" element={<JobVacancies />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/users" element={<Users />} />

        </Route>
        <Route element={<StudentPrivateRoutes/>}>
          <Route path="/studentDashboard" element={<StudentDashboard />} />
          <Route path="/studentProfile" element={<Profile />} />
          <Route path="/banking-details" element={<BankingDetails />} />
        </Route>
        <Route element={<RegPrivateRoutes/>}>
          <Route path="/registration" element={<Registration/>} />
        </Route>
        
        <Route path="/sign-up" element={<Signup/>} />
        <Route path="/faqs" element={<Faqs />} />

        
      </Routes>
    </Router>
  );
};

export default MainRoutes;