import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ITCApplicants() {
  const [applicants, setApplicants]= useState([])
  const [applicantsLoading, SetApplicantsLoading] = useState(false)
  const [applicantsDetailsModal, SetApplicantsDetailsModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [applicantsDetailsForm] = Form.useForm()
  
  const config = Config()
  useEffect(() => {
    getITCStudents()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };
  const triggerToast = () => {
    toast("Application Approved successfully");
  }
  const triggerError = () => {
    toast("Failed to approve applicant");
  }
  const getITCStudents = () => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}applicants/itc_applicants/`;
    axios
      .get(url, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          setApplicants(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const ApproveApplicants = (values) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}approve/student/itc/`;
    const payload = {
      id: values.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/itc_applicants';
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        triggerError()
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };

  const rejectApplicants = (rowData) => {
    SetApplicantsLoading(true)
    const url = ` ${config.base_url}reject/student/itc/`;
    const payload = {
      id: rowData.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        SetApplicantsLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/itc_applicants';
        } else if (res.status === 401) {
          window.location.href = '/itc_applicants';
        }
      })
      .catch((error) => {
        SetApplicantsLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const applicantsData = (applicants.Applicants ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const applicantsHeaders = [
    { label: 'Title', key: 'title' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Gender', key: 'gender' },
    { label: 'Whatsapp Number ', key: 'whatsapp_number'},
    { label: 'ID Number ', key: 'id_number'},
    { label: 'Degree Name', key: 'degree_name'},
    { label: 'University Name', key: 'university_name'},
    { label: 'CTA Support', key: 'cta_support'},
    { label: 'Repeating ITC', key: 'repeating_itc'},
    { label: 'Last ITC Attempt', key: 'last_itc_attempt'},
    { label: 'ITC Attempts', key: 'itc_attempts'},
    { label: 'Year CTA Completed', key: 'year_cta_completed'},
    { label: 'Employment Status', key: 'employment_status'},
    { label: 'Employer Name', key: 'employer_name'},
    { label: 'Training Officer Name', key: 'training_officer_name'},
    { label: 'Training Officer Contact', key: 'training_officer_contact'},
    { label: 'Registration Number ', key: 'reg_number'},

  
  ]

  return (
    <>
      <ToastContainer />
      <Modal
        style={{ marginTop: '-20px' }}
        visible={applicantsDetailsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            SetApplicantsDetailsModal(false)
        }}
        width={800}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Applicants Details</h5>
                 
                  <Spin spinning={applicantsLoading}>
                    <Form
                      form={applicantsDetailsForm}
                      onFinish={(values) => ApproveApplicants(values)}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Title </label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'title'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <Form.Item name='id' hidden={true} />
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Applicant Name</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'first_name'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Last Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'last_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Phone Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'phone_number'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'email'
                          >
                        <input type="email" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Gender</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'gender'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">ID Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'id_number'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Degree Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'degree_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">University Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'university_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">CTA Support</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'cta_support'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Repeating ITC</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'repeating_itc'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Last ITC Attempt</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'last_itc_attempt'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Year CTA Completed</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'year_cta_completed'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
          
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Is Employed</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'employment_status'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employer Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'employer_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employer Contact Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'training_officer_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Employer Phone Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'training_officer_contact'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Registration Number</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'reg_number'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Approve Applicant</button>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
    <div className="pagetitle">
      <h1>ITC Applicants</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Applicants</li>
          <li className="breadcrumb-item active">ITC Applicants</li>
        </ol>
       
      </nav>
    </div>
    <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Applicants
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
              <CSVLink
              className="ms-3 btn btn-circle-lg green-gradient-bg-1  text-white fw-800 border-0 rounded-3 box-shadow-btn"
              data={applicantsData}
              headers={applicantsHeaders}
              filename={'ITC Applicants.csv'}
            >
              Download <FaDownload />
          </CSVLink>
                <div className="table-responsive">
                <Spin spinning={applicantsLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: ' Title', field: 'title' },
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Phone Number', field: 'phone_number' },
                    { title: 'Whatsapp Number ', field: 'whatsapp_number'},
                    { title: 'Registration Number', field: 'reg_number' },
                    
                    {
                      title: 'Date Created',
                      field: 'date_created',
                      render: rowData => {
                        const date = new Date(rowData.date_created);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        const year = date.getFullYear();
                        return `${day}-${month}-${year}`;
                      }
                    },
                    
                    {
                      title: 'National ID',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.national_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                    {
                      title: 'Latest Results',
                      render: rowData => (
                        <a
                          href={`${config.main_url}${rowData.latest_results}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn float-right blue-gradient-bg-1 rounded-2 text-white fw-800 border-0 px-4"
                        >
                          <FaEye />
                        </a>
                      ),
                    },
                   
                  ]}
                  actions={[
                    {
                      icon: () => <FaCheck />,
                      tooltip: 'Approve Application',
                      onClick: (event, rowData) => {
                        setRowData(rowData)
                        applicantsDetailsForm.setFieldsValue({
                          ...rowData,
                        })
                        SetApplicantsDetailsModal(true)
                      },
                    },
                    {
                      icon: () => <MdCancel />,
                      tooltip: 'Reject Application',
                      onClick: (event, rowData) => {
                       rejectApplicants(rowData)
                      },
                    },
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={applicantsData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default ITCApplicants
