import React from 'react'
import Header from '../Header'
import SideNav from './Sidenav'

function BankingDetails() {
  return (
    <div>
      <Header />
      <SideNav />
      <div className="pagetitle">
        <h1>Banking Details</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">Students</li>
            <li className="breadcrumb-item active">Banking-Details</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Banking Details</h5>

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Detail</th>
                      <th scope="col">Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Bank Name</td>
                      <td>CBZ Bank</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Account Name</td>
                      <td>ACCOUNTS ON CALL INSTITUTE PVT LTD</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Account Number (USD)</td>
                      <td>12626389410020</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Branch</td>
                      <td>E-branch</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>Branch Sort Code</td>
                      <td>6137</td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>Swift Code</td>
                      <td>COBZZWHA</td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>Reference</td>
                      <td>Initial and Surname (e.g. J Choto)</td>
                    </tr>
                  </tbody>
                </table>

                <p className="mt-4">
                  <b>Important:</b> After making the payment, kindly send your proof of payment to <b>finance@accsoncall.com</b> for confirmation.
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BankingDetails
