import React, { useState, useEffect } from 'react'
import {Modal, Form, Spin} from 'antd'
import Config from '../../config/config'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { FaCheck, FaDownload, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import {MdCancel} from 'react-icons/md';
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Users() {
  const [applicants, setUsers]= useState([])
  const [applicantsLoading, setUsersLoading] = useState(false)
  const [applicantsDetailsModal, setUsersDetailsModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [applicantsDetailsForm] = Form.useForm()
  
  const config = Config()
  useEffect(() => {
    getAllUsers()
  }, [])

  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const triggerToast = () => {
    toast("Application Approved successfully");
  }
  const triggerUserToast = () => {
    toast("User Added successfully");
  }
  const triggerError = () => {
    toast("Failed to approve applicant");
  }
  const triggerPassword = () => {
    toast("Password and confirm password do not match");
  }
  const getAllUsers = () => {
    setUsersLoading(true)
    const url = ` ${config.base_url}users/all_users/`;
    axios
      .get(url, headers)
      .then((res) => {
        setUsersLoading(false)
        if (res.status === 200) {
          setUsers(res.data);

        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setUsersLoading(false)
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
          // Notifications('error', 'Error', 'Failed to load Students')
        }
      });
  };
  const AddUsers = (values) => {
    if (values.password === values.confirm_password){
    setUsersLoading(true)
    const url = ` ${config.base_url}users/add_user/`;
    const payload = {
     first_name: values.first_name,
     last_name: values.last_name,
     email: values.email,
     password: values.password,
     username: values.username
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        setUsersLoading(false)
        if (res.status === 200) {
          triggerUserToast()
          window.location.href = '/users';
        } else if (res.status === 401) {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        setUsersLoading(false)
        triggerError()
        if (error.response && error.response.status === 401) {
          console.log(error)
        }
      });}else{
        triggerPassword()
      }
  };

  const rejectApplicants = (rowData) => {
    setUsersLoading(true)
    const url = ` ${config.base_url}reject/student/cta/`;
    const payload = {
      id: rowData.id,
    }
    axios
      .post(url, payload, headers)
      .then((res) => {
        setUsersLoading(false)
        if (res.status === 200) {
          triggerToast()
          window.location.href = '/cta_applicants';
        } else if (res.status === 401) {
          window.location.href = '/cta_applicants';
        }
      })
      .catch((error) => {
        setUsersLoading(false)
        if (error.response && error.response.status === 401) {
          // Notifications('error', 'Error', 'Failed to approve Students')
          window.location.href = '/';
        }
      });
  };
  
  const applicantsData = (applicants ?? []).map((item) => {
    return {
      ...item,
    };
  });
  const applicantsHeaders = [
    { label: 'username', key: 'username' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Is Super User', key: 'is_superuser' },

  ]

  return (
    <>
      <ToastContainer />
      <Modal
        style={{ marginTop: '-20px' }}
        visible={applicantsDetailsModal}
        footer={false}
        closable={true}
        onCancel={() => {
            setUsersDetailsModal(false)
        }}
        width={800}
      >
        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add User</h5>
                 
                  <Spin spinning={applicantsLoading}>
                    <Form
                      form={applicantsDetailsForm}
                      onFinish={(values) => AddUsers(values)}
                      name="editOrderForm"
                      autoComplete="off"
                    >
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Username</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'username'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">First Name</label>
                      <div class="col-sm-10">
                        <Form.Item
                          name = 'first_name'
                          >
                            <input type="text" class="form-control" id="inputText"/>
                          </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputLastName" class="col-sm-2 col-form-label">Last Name</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'last_name'
                          >
                        <input type="text" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'email'
                          >
                        <input type="email" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Password</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'password'
                          >
                        <input type="password" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Confirm Password</label>
                      <div class="col-sm-10">
                      <Form.Item
                          name = 'confirm_password'
                          >
                        <input type="password" class="form-control" id="inputEmail"/>
                      </Form.Item>
                      </div>
                    </div>
                     
                    
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary">Add User</button>
                    </div>
                  </Form>
                  </Spin>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal> 
    <div className="pagetitle">
      <h1>Users</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/">Home</a></li>
          <li className="breadcrumb-item">Users</li>
          <li className="breadcrumb-item active">All Users</li>
        </ol>
        <button type="submit" class="btn float-right blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"  onClick= {() => setUsersDetailsModal(true)}>
         Add User
        </button>
      </nav>
    </div>
   
    <section className="section">
      <div className="">
          <div className="card border-0 p-0 rounded-0 box-shadow-1">
              <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between align-items-center px-4">
                  <>
                    <h4 className="text-white fw-700 fs-16px m-0">
                    Users
                    </h4>
                  </>
              </div>
              <div className="card-body px-0 pt-2 pb-4">
                <div className="table-responsive">
                <Spin spinning={applicantsLoading}>
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'Title', field: 'title' },
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Gender', field: 'gender' },
                    { title: 'id_number', field: 'id_number' },
                    { title: 'Whatsapp Number', field: 'whatsapp_number' },
                    { title: 'Date of Birth', field: 'date_of_birth' },
                    { title: 'Address', field: 'address' },
                    { title: 'Student', field: 'is_student' },
                  ]}
                  // isLoading={isCustomerOrderDetailsLoading}
                  data={applicantsData}
                />
                </Spin>
                </div>
              </div>
            </div>
          </div>
    </section>
  </>
  )
}

export default Users
